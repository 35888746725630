.navbar {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
  }
  
  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1500px;
  }

  
  .navbar-logo {
    color: #0f0f0f;
    justify-self: start;
    cursor: pointer;
    text-decoration: none;
    display: flex;
    align-items: left;
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 4rem;
    font-weight: 700;
    font-family: "Days One";
    font-style: normal;
    font-variant: normal;
    transform: rotate(90deg) translate(38%, 105%);
  }

  .fa-typo3-active {
    margin-left: 0.5rem;
    font-size: 4rem;
    font-weight: 700;
    font-family: "Days One";
    font-style: normal;
    font-variant: normal;
    color:#b0b0af;
    transform: rotate(90deg) translate(38%, 105%);
  }

  .fn {
    margin-left: 0.5rem;
    font-size: 4rem;
    font-weight: 700;
    font-family: "Days One";
    font-style: normal;
    font-variant: normal;
    display: flex;
  }

  .test {
    top: -100%;
    visibility: hidden;
  }

  .fnic {
    transform: rotate(90deg) translate(-30%, -30%);
  }

  .fnic-active {
    color:#b0b0af;
    transform: rotate(90deg) translate(-135%, 18%);
  }

  .footer {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
  }
  .headercindy {
    position: fixed;
    z-index: 2;
    top: 0;
    left: 0;
  }
  .headermenu {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
  }
  
  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 20%;
    height: 100%;
    position: fixed;
    left: -100%;
    opacity: 1;
    padding-top: 90px;
    top: -30px;
  }

  .nav-menu.active {
    left: 0;
    opacity: 1;
    z-index: 1;
    text-decoration: none;
    background-color:rgb(205, 184, 164);
    transition: all 1s;
  }
  
  .nav-item {
    height: 80px;
    list-style-type: none;
  }
  
  .nav-links {
    color: #faf6f6;
    font-weight: 400;
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    font-family: "Nobile";
  }
  
  .nav-links:hover {
    font-size: 1.5rem;
    letter-spacing: 2.5px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #c1bebd;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-20%);
    font-size: 5rem;
    cursor: pointer;
  }

  .icon-active {
    color:#b6b6b2;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
    
    .nav-menu.active {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        opacity: 1;
        align-items: center;
        left: 0;
        background-color: rgb(205, 184, 164);
        position: fixed;
      }

      .nav-links {
        display: block;
        padding: 0rem 0rem;
        position: fixed;
      }
      
    .navbar-logo {
      position: absolute;
      top: 0;
      left: 0;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      margin: 2rem auto;
      border-radius: 4px;
      width: 100%;
      text-decoration: none;
      font-size: 1.5rem;
      background-color: transparent;
      color: #fff;
      padding: 14px 20px;
      border: 1px solid #fff;
      transition: all 0.3s ease-out;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #242424;
      transition: 250ms;
    }
  }